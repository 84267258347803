import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from "react-intl"
import KeyValueTable from 'components/_LivePreview/Shared/KeyValueTable'
import NewKeyValueTable from 'components/_LivePreview/Shared/NewKeyValueTable'
import BearingTable from 'components/_LivePreview/Shared/BearingTable'
import {
    getInvestigationDetailsData,
    fixReportDate,
    getOtherSKFdetailsData,
    transformPersonalInfo,
    transformCustomerDetailsData,
    checkIfInvestigationDetailsExits
} from "../Shared/LivepreviewHelperFunctions"
import { checkIfAnyBearingHasDesignationBrandOrRecommendations, } from "../Shared/GetAndRenderBearingDesignation";


const UsInspectionReportInformation = ({ data, config, intl, isUserExternal, prefixes }) => {
  var detailsHeaderTitle;

  if (data["skfDetails.reportNeedsApproval"]) {
    data["skfDetails.approver.email"] = data["skfDetails.reportNeedsApproval"].approversName.value
    data["skfDetails.approver.name"] = data["skfDetails.reportNeedsApproval"].approversName.label
    const approverPersonalInfo = transformPersonalInfo("skfDetails.approver", data);

    if (approverPersonalInfo.data["skfDetails.approver.personalInfo"] !== undefined) {
      data["skfDetails.reportNeedsApproval"] = approverPersonalInfo.data["skfDetails.approver.personalInfo"]
    }
  }

  prefixes.map((prefix => {
    const personalInfo = transformPersonalInfo(prefix, data);
    data[prefix] = personalInfo.data[`${prefix}.personalInfo`]
  }))


  config.reportDetails.map((element => {
    const key = element.key
    if (element.attributeType === "hour") {
      if (data[key]) {
        if (parseFloat(data[key]) > 1) {
          data[key] = `${data[key]} ${intl.formatMessage({ id: "hours" })}`
        } else {
          data[key] = `${data[key]} ${intl.formatMessage({ id: "hour" })}`
        }
      }
    }
  }))

  if (isUserExternal || isUserExternal == "TRUE") {
    detailsHeaderTitle = intl.formatMessage({ id: "skfDetailsExternal" })
  } else {
    detailsHeaderTitle = intl.formatMessage({ id: "skfDetails.livePreview.header" })
  }

  const filterConfig = (filter, config) => {
    return config.filter(item => filter.some(f => item.key.includes(f)));
  }

  const getReportDetailsConfig = () => { 
    const reportConfig = [...config.reportDetails, ...filterConfig([
      "skfDetails.endUser.territoryManager_contact",
      "skfDetails.endUser.industrySpecialist_contact",
      "skfDetails.distributionList"], config.skfDetails)];
    return reportConfig;
  }

  return (
    <div className="live-preview-report-information">
      <NewKeyValueTable
        config={getReportDetailsConfig()}
        sectionId="reportDetails"
        data={fixReportDate(config.reportDetails, data)} />
      {
        config.customerDetails && <KeyValueTable
          header={intl.formatMessage({ id: "customerDetails.livePreview.header" })} config={config.customerDetails} sectionId="customerDetails" data={transformCustomerDetailsData(data, config.customerDetails)} />
      }
      {
        config.skfDetailsOther && <KeyValueTable
          header={detailsHeaderTitle}
          config={config.skfDetailsOther} sectionId="skfDetails"
          data={getOtherSKFdetailsData(config.skfDetailsOther, data)} />
      }
    </div>
  )
};

UsInspectionReportInformation.propTypes = {
  reportDetails: PropTypes.object,
  investigationsDetails: PropTypes.object,
  reportedBy: PropTypes.object
}

export default injectIntl(UsInspectionReportInformation)
