import { saveAllAnswersAndValidate } from "redux/thunks/answerThunk";
import { callBackThunkAsPromise, cloneCase } from "redux/thunks/caseThunks";
import { getCaseDataRepositoryWithoutOffline } from "core/caseRepository/caseRepositoryFactory";

export default async function duplicateCase(caseId, caseStatus, canUserEdit, dispatchF, intl) {

    if (caseStatus === "draft") { 
        if (canUserEdit) { 
            await callBackThunkAsPromise(dispatchF, saveAllAnswersAndValidate);
        }
    }
    
    const repository = getCaseDataRepositoryWithoutOffline();
    const response = await repository.cloneCase(caseId);
    alert(intl.formatMessage({ id: "formRenderer.caseDuplicated" }) + response.data.id);
}