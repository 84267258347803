import React from "react";
import LockSvg from "components/svg/Lock";
import { FormattedMessage } from "react-intl";

const StatusConfidential = ({ caseStatus, isConfidential }) => {
  const printHtmlHbs = "{{{printHtml}}}";
  if (caseStatus === "approvalEdited") { 
    caseStatus = "submitted";
  }
  return (
    <div className="live-preview-status-confidential">
      <div className="print">
        { printHtmlHbs }
      </div>
      <div className="live-preview-status-confidential__status">
        <span><FormattedMessage id={ `preview.${caseStatus}` } /></span>
        {isConfidential && (
          <span className="live-preview-status-confidential__confidential">
            <LockSvg /> <FormattedMessage id="shared.confidential" />
          </span>
        )}
      </div>
    </div>
  );
};

export default StatusConfidential;
