import React, { useState } from "react";
import PropTypes from "prop-types";
import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import ReactModal from "react-modal";
import { withRouter } from "react-router-dom";
import { dispatch } from "use-bus";
import { injectIntl } from "react-intl";
import { getCaseDataRepositoryWithoutOffline } from "core/caseRepository/caseRepositoryFactory";

const TableCellOverlay = ({ caseId, history, status, intl, rowPrefix }) => {
  ReactModal.setAppElement("#app");

  
  const closeOverlay = event => {
    if (event !== undefined) {
      event.stopPropagation();
    }

    const row = document.querySelector(`[data-row-id="${!rowPrefix ? caseId : (rowPrefix + caseId)}"]`);
    row.classList.remove("table__body-row--is-active");
  };

  const clearOverlays = () => {
    const overlays = document.querySelectorAll(".table__body-row--is-active");

    overlays.forEach(row => {
      row.classList.remove("table__body-row--is-active");
    });
  };


  const duplicateCase = (event, id) => {
    event.stopPropagation();

    const repository = getCaseDataRepositoryWithoutOffline();
    repository.cloneCase(id).then(response => { 
      clearOverlays();
      dispatch("@@ui/MANUAL_DATA_GET");
    }).catch(error => alert(error));
  };


  const redirectToCase = event => {
    event.stopPropagation();

    history.push("/case/" + caseId);
  };

  return (
    <div className="table__body-overlay-element">
      <BaseButton
        text={intl.formatMessage({
          id: "tableCellOverlay.openReport"
        })}
        backgroundColor="green"
        handleSubmit={event => {
          redirectToCase(event);
        }}
      />

      <BaseButton
        text={intl.formatMessage({
          id: "tableCellOverlay.duplicate"
        })}
        backgroundColor="gray"
        handleSubmit={event => {
          duplicateCase(event, caseId);
        }}
      />

      <BaseButton
        className="table__body-overlay-element-close"
        iconComponent={<CircleCloseFilterSvg />}
        handleSubmit={event => closeOverlay(event)}
      />
    </div>
  );
};

TableCellOverlay.propTypes = {
  caseId: PropTypes.string,
  status: PropTypes.string
};

export default injectIntl(withRouter(TableCellOverlay));
