import React, { useEffect, useState } from 'react'
import SvgCanvasColorSwatch from "components/svg/CanvasColorSwatch";
import { connect } from "react-redux";

const colors = [
    {
        value: "green",
        colorCode: "#fff",
        colorCodeSelected: "rgb(6, 186, 91)",
        text: "minor"
    },
    {
        value: "yellow",
        colorCode: "#fff",
        colorCodeSelected: "rgb(255, 189, 0)",
        text: "moderate"
    },
    {
        value: "red",
        colorCode: "#fff",
        colorCodeSelected: "rgb(240, 0, 0)",
        text: "severe"
    }
]



const TrafficLight = ({ severity, scrollKey, scrollTarget }) => {

    const [isScrollTarget, setIsScrollTarget] = useState(false);

    useEffect(() => {
        setIsScrollTarget(hasScrollTarget());
     }, [scrollTarget]);


    const hasScrollTarget = () => {
        if (scrollTarget) {
            if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
                return scrollTarget.fieldTarget === scrollKey || scrollTarget.scrollTarget.includes(scrollKey);
            }
        }
        return false;
    }

    const renderLight = (c, index) => {
        return  <div key={index}>
            <SvgCanvasColorSwatch
                strokeColor="#485A64"
                fillColor={c.value === severity.value || severity.value === undefined ? c.colorCodeSelected : c.colorCode}
                size="18px"/>
        </div>
    }

    return (severity.value) || isScrollTarget ? <div className="traffic-light">
        {colors.map((c, index) => renderLight(c, index))}
    </div> : null;
}

const mapStateToProps = (state) => {
    return {
      scrollTarget: state.saveComponentValue.scrollTarget
    };
};
  
export default connect(mapStateToProps)(TrafficLight);