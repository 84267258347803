import React, { Component } from "react";
import { Amplify, Auth } from "aws-amplify";
import { BrowserRouter as Router, withRouter, Route } from "react-router-dom";
import Routes from "./Routes";
import config from "./config";
import TheHeader from "components/common/TheHeader";
import TheEnvironmentBar from "components/common/TheEnvironmentBar";
import TheUserBar from "components/common/TheUserBar";
import TheFooter from "components/common/TheFooter";
import TheToTop from "components/common/TheToTop";
import ServiceWorkerUpdateModal from "./ServiceWorkerUpdateModal";
import languageTranslations from "./language_translation.json";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import CacheBuster from "./CacheBuster";
import { setCurrentUser } from "./redux/actions/currentUserActions";
import Offline from "components/_Offline/Offline";
import OfflineBar from "components/_Offline/OfflineBar";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      locale: "",
      isUserExternal: false,
      user: null
    };

    Amplify.configure({
      Auth: config.COGNITO_AUTH,
      Storage: {
        AWSS3: {
          bucket: config.BUCKET,
          region: "eu-west-1",
        },
      },
    });
  }

  async componentDidMount() {
    
    
    if (process.env.REACT_APP_STAGE === "stage") {
      console.log("Initialized Google analytics for stage");
      ReactGA.initialize("UA-30046188-41");
      ReactGA.pageview("/dashboard");
    } else if (process.env.REACT_APP_STAGE === "prod") {
      console.log("Initialized Google analytics for prod");
      ReactGA.initialize("UA-30046188-41");
      ReactGA.pageview("/dashboard");
    } else if (process.env.REACT_APP_STAGE === undefined) {
      console.log("Initialized Google analytics for dev");
      ReactGA.initialize("UA-113741941-2");
      // ReactGA.initialize('UA-113741941-2', {
      //   debug: true,
      // });
      ReactGA.pageview("/dashboard");
    }
    
    
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
      Auth.currentAuthenticatedUser().then((userAuth) => {
        const isExternal = userAuth.attributes["custom:external"]?.toLocaleString()?.toLocaleLowerCase() === "true";
        this.setUserExternal(isExternal);
        this.setUser(userAuth.attributes);
      });
      
    } catch (e) {
      if (e !== "No current user") {
        console.log(e);
      }
    }
  }



  userHasAuthenticated = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUserExternal = (isExternal) => {
    this.setState({ isUserExternal: isExternal });
  };

  setUser = (user) => {
    this.setState({ user: user });
    this.props.dispatch(setCurrentUser(user));
  };

  getMessages = () => {
    // If we want to merge to default to english if translations doesn't exists
    return Object.assign(
      {},
      languageTranslations["en"],
      languageTranslations[this.props.currentLanguage]
    );

    // If we want to default to translation key
    // return languageTranslations[this.props.currentLanguage]
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setUserExternal: this.setUserExternal,
      setUser: this.setUser,
      isUserExternal: this.state.isUserExternal,
      user: this.state.user
    };

    const notcase = /^(?!.*(\/case)).*$/
    return (
      <>
        <CacheBuster isconnected={this.props.connection}>
        <Offline />
        <Router exact>
          <IntlProvider
            locale={this.props.currentLanguage}
            defaultLocale={"en"}
            //defaultMessages={languageTranslations["en"]}
            messages={this.getMessages()}
            >
            <ServiceWorkerUpdateModal />
            <div id="app" className="App page-layout">
                <OfflineBar/>
                <TheEnvironmentBar />
              <Route
                path={notcase}
                render={() => <TheUserBar childProps={childProps} />}
              />
              <Route
                path={notcase}
                render={() => (
                  <TheHeader
                    childProps={childProps}
                    isDisconnected={!this.props.connection}/>
                )}
              />
              <Routes childProps={childProps} />
              <Route path={notcase} render={() => <TheFooter isUserExternal={this.state.isUserExternal} />} />
              <TheToTop />
            </div>
          </IntlProvider>
          </Router>
          </CacheBuster>
        </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.language,
    connection: state.connection
  };
}
export default withRouter(connect(mapStateToProps)(App));
