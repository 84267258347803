import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import queryString from "query-string";
import { useMediaQuery } from "react-responsive";
import { TO_MEDIUM, MEDIUM } from "core/ui/breakpoints";
import BaseButton from "components/common/BaseButton";
import BaseInput from "components/common/BaseInput";
import BaseCheckbox from "components/common/BaseCheckbox";
import SiloSvg from "components/svg/Silo";
import CircleCheckSvg from "components/svg/CircleCheck";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import RemoveSvg from "components/svg/Remove";
import ArrowDownSvg from "components/svg/ArrowDown";
import {
  toggleFilterGroupIsOpen,
  searchFilterItem
} from "redux/actions/filterActions";
import { withRouter } from "react-router-dom";
import SearchSvg from "components/svg/Search";
import DateRangeFilter from "./DateRangeFilter";
import { injectIntl } from "react-intl";

const isValidDate = date => {
  const verifyDate = new Date(date);
  return typeof verifyDate.getMonth === "function";
};

export const getQueryFiltersFromURL = () => {
  const URLSearch = queryString.parse(window.location.search);

  const filters = {};
  if (URLSearch.filters) {
    URLSearch.filters.split(",").forEach(filterString => {
      const separatedVal = filterString.split(":");
      filters[separatedVal[0]] = decodeURI(separatedVal[1]);
    });
  }

  return filters;
};

export const getFilterQuery = filters => {
  let filterQueryString = "";
  Object.keys(filters).forEach(queryGroupName => {
    filterQueryString += queryGroupName + ":" + filters[queryGroupName] + ",";
  });
  filterQueryString = filterQueryString.slice(0, -1);
  return filterQueryString;
};

const getAppliedFilters = filters => {
  let arraysOfAppliedFilters = Object.keys(filters)
    .filter(groupName => groupName !== "date")
    .map(groupName =>
      filters[groupName].items
        .filter(item => item.isSelected)
        .map(item => ({ ...item, groupName }))
    );

  const URLFilters = getQueryFiltersFromURL();
  const dateParams = ["from", "to"];
  dateParams.forEach(dateParam => {
    if (URLFilters[dateParam] && isValidDate(URLFilters[dateParam])) {
      const filterDate = new Date(URLFilters[dateParam]);
      const viewDate = `${filterDate.toLocaleDateString()}`; // TODO: intl date
      arraysOfAppliedFilters = [
        ...arraysOfAppliedFilters,
        [
          {
            value: `${dateParam} ${viewDate}`,
            groupName: dateParam,
            isSelected: true,
            date: filterDate
          }
        ]
      ];
    }
  });

  return [].concat.apply([], arraysOfAppliedFilters);
};

const Filter = ({
  filters,
  appliedFilters,
  loading,
  toggleFilterGroupIsOpen,
  searchFilterItem,
  history,
  className,
  intl,
  isUserExternal
}) => {
  // state
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // methods
  const toMedium = useMediaQuery({
    query: TO_MEDIUM
  });

  const medium = useMediaQuery({
    query: MEDIUM
  });

  const handleToggleFilterOpen = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleToggleFilterGroup = groupName => {
    toggleFilterGroupIsOpen(groupName);
  };

  const handleClearAllFilters = () => {
    history.push(history.location.pathname);
  };

  const handleClearFiltersInGroup = groupName => {
    let filters = getQueryFiltersFromURL();
    if (groupName === "date") {
      delete filters["from"];
      delete filters["to"];
    } else {
      delete filters[groupName];
    }

    navigateOnFilterChange(filters);
  };

  const handleToggleFilterValue = (groupName, item) => {
    let filters = getQueryFiltersFromURL();
    if (item.isSelected) {
      delete filters[groupName];
    } else {
      filters = { ...filters, [groupName]: item.value };
    }

    navigateOnFilterChange(filters);
  };

  const handleDateChange = (dateObject, name) => {
    let filters = getQueryFiltersFromURL();
    let date = dateObject.dateObject
    const calYear = date.getFullYear();
    const calMonth = ("0" + (date.getMonth() + 1)).slice(-2);
    const calDate = ("0" + date.getDate()).slice(-2);
    const formattedDate = `${calYear}-${calMonth}-${calDate}`;

    filters = {
      ...filters,
      [name]: formattedDate
    };

    navigateOnFilterChange(filters);
  };

  const handleFilterSearch = (groupName, searchValue) => {
    searchFilterItem(groupName, searchValue);
  };

  const getAllFiltersInGroupIsSelected = groupName => {
    return (
      groupName !== "date" &&
      filters[groupName].items.length > 0 &&
      filters[groupName].items.filter(item => item.isSelected).length ===
        filters[groupName].items.length
    );
  };

  const getFilterIsSearchable = groupName => {
    return false;
  };

  const navigateOnFilterChange = filters => {
    let params = queryString.parse(history.location.search);

    delete params["page"]; // navigate to first page on filter change

    if (Object.keys(filters).length === 0) {
      delete params["filters"];
      history.push("?" + queryString.stringify(params));
    } else {
      let filterQueryString = getFilterQuery(filters);
      params = {
        ...params,
        filters: filterQueryString
      };
      history.push("?" + queryString.stringify(params));
    }
  };

  const translateFilterLabel = (groupName, label) => { 
    if (groupName === "status") { 
      return intl.formatMessage({ id: "preview." + label });
    }
    return label;
  }

  const getLoadingClass = () =>
    loading ? "element--is-loading element--is-loading-after " : "";

  return (
    <aside className={classnames("filter", className)}>
      {toMedium && (
        <BaseButton
          text={intl.formatMessage({ id: "filter.filters" })}
          className="filter__filter-toggler"
          handleSubmit={handleToggleFilterOpen}
        >
          <SiloSvg className="filter__silo-icon" />
          <CircleCheckSvg
            v-if="returnAllActiveAlternatives.length > 0"
            className="filter__check-icon"
          />
        </BaseButton>
      )}

      {((toMedium && isFilterOpen) || medium) && (
        <div className={getLoadingClass() + "filter-container"}>
          {toMedium && (
            <BaseButton
              text={intl.formatMessage({ id: "filter.filters" })}
              className="filter-container__close-header"
              handleSubmit={handleToggleFilterOpen}
            >
              <CircleCloseFilterSvg className="filter-container__close-header-svg" />
            </BaseButton>
          )}

          {toMedium && (
            <BaseButton
              text={intl.formatMessage({ id: "filter.seeResults" })}
              className="filter-container__see-results"
              handleSubmit={handleToggleFilterOpen}
            />
          )}

          {appliedFilters && appliedFilters.length > 0 && (
            <>
              <div className="fiter-container__selected-filters">
                {appliedFilters.map(item => (
                  <BaseButton
                    key={`selected-filter-${item.groupName}-${item.value}`}
                    text={translateFilterLabel(item.groupName, item.value)}
                    className="button--has-icon button--icon-to-right"
                    textToLeft={true}
                    iconToRight={true}
                    backgroundColor="gray"
                    handleSubmit={() =>
                      handleToggleFilterValue(item.groupName, item)
                    }
                    iconComponent={<RemoveSvg />}
                  />
                ))}
              </div>
              <div className="filter-container__button-actions">
                <BaseButton
                  text={intl.formatMessage({ id: "filter.clearAllFilters" })}
                  backgroundColor="gray"
                  handleSubmit={handleClearAllFilters}
                />
              </div>
            </>
          )}

          <div className="filter-container__groups">
            {Object.keys(filters).filter(f => f == "user_company" ? !isUserExternal : true).map(groupName => (
              <div
                key={`filter-group-${groupName}`}
                className={classnames("filter-group", {
                  "filter-group--is-open": false
                })}
              >
                <button
                  className={classnames("filter-group__toggler", {
                    "filter-group__toggler--is-active":
                      filters[groupName].isOpen
                  })}
                  onClick={() => handleToggleFilterGroup(groupName)}
                >
                  {intl.formatMessage({ id: `filter.${groupName}` })}
                  {getAllFiltersInGroupIsSelected(groupName) && (
                    <CircleCheckSvg
                      strokeColor={"#88C008"}
                      className="filter-group__check-svg"
                    />
                  )}
                  <ArrowDownSvg className="filter-group__arrow-svg" />
                </button>

                {filters[groupName].isOpen && (
                  <div className="filter-group__content">
                    <div className="filter-group__search-clear-actions">
                      {groupName !== "date" &&
                        getFilterIsSearchable(groupName) && (
                          <BaseInput
                            id={`filter-group-search-${groupName}`}
                            value={filters[groupName].searchValue || ""}
                            placeholder={intl.formatMessage({
                              id: "filter.searchFor"
                            })}
                            handleChange={event =>
                              handleFilterSearch(groupName, event.target.value)
                            }
                            label-text={`${intl.formatMessage({
                              id: "filter.filterIn"
                            })} ${groupName}`}
                            className="filter-group__input-container input"
                            iconComponent={
                              <SearchSvg
                                className="input-shell__svg input-shell__svg--right"
                                color="#86989C"
                              />
                            }
                          />
                        )}
                      <BaseButton
                        text={intl.formatMessage({ id: "filter.clearFilter" })}
                        backgroundColor="gray"
                        handleSubmit={() =>
                          handleClearFiltersInGroup(groupName)
                        }
                      />
                    </div>

                    <div
                      className={
                        (groupName === "date" ? "date-picker-group " : "") +
                        "filter-group__select-container"
                      }
                    >
                      {groupName === "date" ? (
                        <DateRangeFilter
                          onDateChange={handleDateChange}
                          appliedFilters={appliedFilters}
                        />
                      ) : (
                        filters[groupName].items
                          .filter(item => !item.hide)
                          .map(item => (
                            <BaseCheckbox
                              key={`filter-group-row-${item.value}`}
                              isFor={`filter-checkbox-${item.value}`}
                              labelText={translateFilterLabel(groupName, item.value)}
                              value={item.isSelected}
                              onChange={() =>
                                handleToggleFilterValue(groupName, item)
                              }
                            />
                          ))
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          {toMedium && (
            <BaseButton
              text={intl.formatMessage({ id: "filter.seeResults" })}
              class="filter-container__see-results-bottom"
              handleSubmit={handleToggleFilterOpen}
            />
          )}
        </div>
      )}
    </aside>
  );
};

Filter.propTypes = {
  filters: PropTypes.object,
  appliedFilters: PropTypes.array,
  loading: PropTypes.bool,
  toggleFilterGroupIsOpen: PropTypes.func,
  searchFilterItem: PropTypes.func,
  history: PropTypes.object,
  className: PropTypes.string,
  isUserExternal: PropTypes.bool
};

const mapStateToProps = state => ({
  filters: state.filters.filterGroups,
  appliedFilters: getAppliedFilters(state.filters.filterGroups)
});

const mapDispatchToProps = dispatch => ({
  toggleFilterGroupIsOpen: groupName =>
    dispatch(toggleFilterGroupIsOpen(groupName)),
  searchFilterItem: (groupName, searchValue) =>
    dispatch(searchFilterItem(groupName, searchValue))
});

export default injectIntl(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(Filter)
  )
);
