import { getCaseDataRepository } from "core/caseRepository/caseRepositoryFactory";
import metadata from "./metaDataList.json";
import getCaseMetadataForAnswer from "./getCaseMetadata";
import { setCurrentCase, addCaseToSave, removeCaseToSave } from "redux/actions/currentCaseActions";
import { loadFormTemplate, resetFormTemplate } from "redux/thunks/formTemplateThunks";
import { loadAnswers, resetAnswers } from "redux/thunks/answerThunk";
import { setCurrentCaseAccess } from "redux/actions/currentCaseAccessActions";
import { resetBearingsExpandedState } from "redux/actions/isBearingsExpandedActions";
import { setActiveTab } from "redux/actions/activeTabActions";
import { resetIsRailwayWheelsetBearing } from "redux/actions/isRailwayWheelsetBearingActions";
import { clearAnswersToSave } from "redux/actions/saveComponentValueAction";
import { findDomainFromTemplateId } from "components/_LivePreview/Shared/LivepreviewHelperFunctions";
import config from "../../config";

export const callBackThunkAsPromise = (dispatch, getAction) => {
    return new Promise(resolve => dispatch(getAction(result => resolve(result))));
}

export const updateCaseForAnswers = (caseData, answers) => async (dispatch, getState) => {
    if (shouldCaseBeUpdated(answers.map(a => a.name))) {
        let caseToUpdate = caseData;
        answers.forEach(answer => {
            const metadata = getCaseMetadataForAnswer(answer.name, answer.value, caseData.formtemplateId);
            caseToUpdate = {
                ...caseToUpdate,
                ...metadata,
                domain: metadata.typeOfReport
            };
        });

        dispatch(addCaseToSave(caseToUpdate));

    }
}

// export const updateCase = (caseData, value, name) => async (dispatch, getState) => { 
//     if (shouldCaseBeUpdated(name)) { 
//         const metadata = getCaseMetadata(name, value, caseData);
//         const caseToUpdate = {
//             ...caseData,
//             ...metadata,
//             domain: metadata.typeOfReport,
//             customer_contact: metadata.customerContactName,
//             title: metadata.reportTitle,
//             ter_reference: metadata.terReferenceNumber,
//         };
//         dispatch(addCaseToSave(caseToUpdate));
//     }
// }

export const saveCase = (caseToUpdate, callback) => async (dispatch, getState) => {
    try {
        const caseResponse = await getCaseDataRepository(getState()).updateCaseData(
            caseToUpdate.id,
            updateApproverAndDelegationList(getState().saveComponentValue.components, caseToUpdate)
        );
        dispatch(setCurrentCase(caseResponse.data));
        dispatch(removeCaseToSave(caseResponse.data.updatedAt));
        callback();
    } catch (error) {
        handleCaseError(error.response.data, dispatch);
    }

    function updateApproverAndDelegationList(currentState, currentCase) {
        const author = currentCase.reportAuthor ? currentCase.reportAuthor : currentCase.author;
        const approver = findApprover(currentState, currentCase);

        const coauthor = findAllCoAuthors(currentState, currentCase);
        return {
            ...currentCase,
            author,
            approver,
            coauthor,
            confidential: isReportConfidential(currentState, currentCase.domain),
        };
    }

    function findApprover(state, currCase) {
        let value;

        switch (currCase.formtemplateId) {
            case config.BART_TEMPLATE_ID:
            case config.WIND_TEMPLATE_ID:
            case config.GBI_TEMPLATE_ID:
                value = findAnswer('skfDetails.reportNeedsApproval');
                value = (value?.approversName?.value || value?.approversName) || currCase.author;
                break;
            case config.US_INSPECTION_TEMPLATE_ID:
                value = findAnswer('reportDetails.reportApprovedBy.email');
                value = value || currCase.author;
                break;
            case config.INSPECTION_TEMPLATE_ID:
                value = findAnswer('skfDetails.reportNeedsApproval');
                const diameterOverTwoHundred = isDiameterOverTwoHundred(value?.diameterOverTwoHundred);
                value = (diameterOverTwoHundred ? value?.approversName : value?.approversName?.value) || currCase.author;
                break;
            default:
                return currCase.author;
        }
        return value;

        function isDiameterOverTwoHundred(value) {
            if (typeof value === 'string') {
                return value.toLowerCase() === 'true';
            } else if (typeof value === 'boolean') {
                return value;
            } else if (typeof value === 'object') {
                return true;
            }
            return false;
        }

        function findAnswer(name) {
            const answerFound = state.find(c => c.name === name);
            return answerFound && typeof answerFound.value === 'string'
                ? JSON.parse(answerFound.value)
                : null;
        }
    }

    // in GBI reports we have four co-authors
    function findAllCoAuthors(components, caseState) {
        if (caseState.domain !== 'GBI') {
            return caseState.coauthor;
        }
        return components
            .filter(c => c.name.startsWith('skfDetails.coReportAuthor') && c.name.endsWith('.email'))
            .map(c => JSON.parse(c.value))
            .filter(c => c.trim())
            .join(', ');
    }

    function isReportConfidential(components, domain) {
        // NAM and BI reports are always public (cannot be confidential)
        if (domain === 'NAMInspection' || domain === 'Inspection') {
            return false;
        }
        try {
            if (domain === 'Wind') {
                const isPublicAnswer = components.find(c => c.name === 'reportDetails.PublicCheckBox');
                const answer = JSON.parse(isPublicAnswer.value);
                // we return the opposite value of the public answer because we store the value as confidential: true/false
                // so if the answer is true, then confidential is false
                return !answer;
            }
            const isPublicAnswer = components.find(c => c.name === 'reportDetails.ConfidentialCheckBox');
            return JSON.parse(isPublicAnswer.value);
        } catch (e) {
            console.log(e);
        }
    }
}

export const addCase = (id, confidential, lang, user, callback) => async (dispatch, getState) => {

    let answers = [
        { name: "reportDetails.reportDate", value: JSON.stringify({ date: new Date().toLocaleDateString(), dateObject: new Date() }) }
    ];
    if (id === config.US_INSPECTION_TEMPLATE_ID) {
        answers = [...answers, { name: "reportDetails.inspectionMadeBy.name", value: JSON.stringify(user.name) }];
        answers = [...answers, { name: "reportDetails.inspectionMadeBy.email", value: JSON.stringify(user.email) }];
        answers = [...answers, { name: "reportDetails.inspectionMadeBy.phone", value: JSON.stringify(user.phone_number) }];
    } else {
        answers = [...answers, { name: "skfDetails.reportAuthor.name", value: JSON.stringify(user.name) }];
        answers = [...answers, { name: "skfDetails.reportAuthor.email", value: JSON.stringify(user.email) }];
        answers = [...answers, { name: "skfDetails.reportAuthor.phone", value: JSON.stringify(user.phone_number) }];
    }

    const response = await getCaseDataRepository(getState()).addCase(id, answers, confidential, lang, findDomainFromTemplateId(id));

    callback(response.data);
}

export const deleteCase = (id, callback) => async (dispatch, getState) => {
    await getCaseDataRepository(getState()).deleteCase(id, getState().currentCase.case.updatedAt);
    dispatch(clearAnswersToSave());
    callback();
}

export const submitCase = (id, callback) => async (dispatch, getState) => {
    const result = await getCaseDataRepository(getState()).submitCase(id);
    callback(result);
}

export const rejectCase = (id, callback) => async (dispatch, getState) => {
    const result = await getCaseDataRepository(getState()).rejectCase(id);
    callback(result);
}

export const editCase = (id, callback) => async (dispatch, getState) => {
    const result = await getCaseDataRepository(getState()).editCase(id);

    const caseResponse = await getCaseDataRepository(getState()).getCase(id);

    if (caseResponse.data.domain === null) {
        const caseData = {};
        addReportType(caseData, caseResponse.data.formtemplateId);
    }

    dispatch(setCurrentCase(caseResponse.data));

    callback(result);
}

export const approveCase = (id, callback) => async (dispatch, getState) => {
    const result = await getCaseDataRepository(getState()).approveCase(id);
    callback(result);
}

export const approvalEditCase = (id, callback) => async (dispatch, getState) => {
    const result = await getCaseDataRepository(getState()).approvalEditCase(id);

    const caseResponse = await getCaseDataRepository(getState()).getCase(id);

    if (caseResponse.data.domain === null) {
        const caseData = {};
        addReportType(caseData, caseResponse.data.formtemplateId);
    }

    dispatch(setCurrentCase(caseResponse.data));

    callback(result);
}

export const restoreCase = (id, callback) => async (dispatch, getState) => {
    const caseResponse = await getCaseDataRepository(getState()).restoreCase(id);
    dispatch(setCurrentCase(caseResponse.data));
    callback();
}

export const loadCaseAndData = (id, connection, answerErrors, caseErrors) => async (dispatch, getState) => {
    try {
        const caseResponse = await getCaseDataRepository(getState(), connection).getCase(id);

        if (caseResponse.data.domain === null) {
            const caseData = {};
            const currentDomain = addReportType(caseData, caseResponse.data.formtemplateId);
            caseResponse.data.domain = currentDomain;
        }

        if (caseErrors?.length > 0) {
            caseErrors.forEach(e => {
                handleCaseError(e.errorData, dispatch)
            })
        }

        dispatch(setCurrentCase({ ...caseResponse.data, isLoading: false }));

        loadFormTemplate(caseResponse.data.formtemplateId)(dispatch, getState);

        loadAnswers(id, answerErrors)(dispatch, getState);

        const caseAccessResponse = await getCaseDataRepository(getState()).getCaseAccess(id);
        dispatch(setCurrentCaseAccess(caseAccessResponse.data));

        dispatch(resetBearingsExpandedState());
        const activeTab = getActiveTabForCaseStatus(caseResponse.data.status);
        if (activeTab) {
            dispatch(setActiveTab(activeTab));
        }
    } catch (error) {
        console.error(error);
        if (error.response) {
            handleNotFoundError(error.response.data, dispatch);
        }

    }

}

export const resetLoadedCase = (callback) => async dispatch => {
    await resetAnswers()(dispatch);
    await resetFormTemplate()(dispatch);
    dispatch(setActiveTab(0));
    dispatch(setCurrentCase(null));
    dispatch(setCurrentCaseAccess({}));
    dispatch(resetIsRailwayWheelsetBearing());
    if (callback) {
        callback();
    }
}

const handleCaseError = (errorData, dispatch) => {
    if (errorData.error.errorType === "ConcurrencyError") {
        const errorCase = {
            ...errorData.error.storedObject,
            isError: true,
            error: errorData.error,
        };
        dispatch(setCurrentCase(errorCase));
    } else {
        console.log("Error when updating case", errorData);
    }
}

const handleNotFoundError = (errorData, dispatch) => {
    if (errorData) {
        if (errorData.error) {
            if (errorData.error.errorType === "NotFoundError") {
                dispatch(setCurrentCase({ isError: true, error: errorData.error, isNotFoundError: true }))
            }
        } else {
            dispatch(setCurrentCase({ isError: true, error: errorData.error }));
        }

    }

}

const getActiveTabForCaseStatus = caseStatus => {
    if (caseStatus === "approved" || caseStatus === "rejected" || caseStatus === "submitted") {
        return 1;
    }
};

const shouldCaseBeUpdated = (names) => {
    return names.some(name => metadata.find(i => i.answerInStore === name) !== undefined);
}

const getCaseMetadata = (name, value, caseData) => {
    return getCaseMetadataForAnswer(name, value, caseData.formtemplateId);
}

const addReportType = (metadata, currentFormTemplateId) => {
    switch (currentFormTemplateId) {
        case config.INSPECTION_TEMPLATE_ID:
            metadata.typeOfReport = "Inspection";
            metadata.domain = "Inspection";
            return "Inspection"
        case config.US_INSPECTION_TEMPLATE_ID:
            metadata.typeOfReport = "NAMInspection";
            metadata.domain = "NAMInspection";
            return "NAMInspection"
        case config.WIND_TEMPLATE_ID:
            metadata.typeOfReport = "Wind";
            metadata.domain = "Wind";
            return "Wind"
        case config.BART_TEMPLATE_ID:
            metadata.typeOfReport = "BDA";
            metadata.domain = "BDA";
            return "BDA"
        case config.GBI_TEMPLATE_ID:
            metadata.typeOfReport = "GBI";
            metadata.domain = "GBI";
            return "GBI"
        default:
            return null;
    }
}
