import React, { useState, useEffect } from "react";
import SkfLogo from "components/svg/SkfLogo";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import DeployFormtemplates from "components/formcomponents/deployFormtemplates";
import { connect } from "react-redux";

const isLocal = () => {
  if (process.env.NODE_ENV === "development") {
    return true;
  } else {
    return false;
  }
};



const TheFooter = ({ isUserExternal, connection, user }) => {  

  const renderPublicFooterLinks = () => {
    return <>
      <a href="https://www.skf.com/group/footer/terms-and-conditions" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.termsOfUse" />
      </a>
      <a href="https://www.skf.com/group/footer/privacy-policy" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.privacypolicy" />
      </a>
      <a href="https://www.skf.com/group/footer/site-ownership" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.siteownership" />
      </a>
      <a href="https://www.skf.com/group/footer/cookies" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.cookies" />
      </a>
      <a href="https://www.skf.com/group/footer/general-conditions-of-sales" className="footer__link" target="_blank" rel="noopener noreferrer">
        <FormattedMessage id="footer.generalconditionsofsales" />
      </a>
    </>
  }

  const renderLoggedInFooterLinks = () => {
    return <>
      <Link to={"/about"} className="footer__link">
          <FormattedMessage id="footer.about" />
      </Link>
      {connection && !isUserExternal ? <>
        <a href="https://skfgroup.sharepoint.com/sites/BART-Public/Shared%20Documents/Forms/AllItems.aspx?viewpath=%2Fsites%2FBART-Public%2FShared%20Documents%2FForms%2FAllItems.aspx" className="footer__link" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="footer.openUserGuide" />
        </a>
        <a href="https://www.yammer.com/skf.com/#/threads/inGroup?type=in_group&feedId=7941463&view=all" className="footer__link" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="footer.addQuestionsOnYammer" />
        </a>
      </> : null}
      { isLocal() ? <DeployFormtemplates ></DeployFormtemplates> : null }
    </>;
  }

  const renderLinks = () => {
    if (user.sub) {
      return renderLoggedInFooterLinks();
    } else {
      return renderPublicFooterLinks();
    }
  }

  return (
    <footer className="footer">
      <div className="footer__container">
        <SkfLogo className="footer__logo" />
        <div key="footer__copyright" className="footer__copyright">
          © <FormattedMessage id="footer.copyright" />
        </div>
        { renderLinks() }
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => {
  return {
    connection: state.connection,
    user: state.currentUser
  };
};

export default connect(mapStateToProps)(TheFooter);
