import React, { Component } from "react";
import getComponent from '../../_Case/ComponentsList'
import { connect } from "react-redux"
import { injectIntl } from "react-intl";
import { getReportType } from "../utils/reportUtils";

class BearingDesignationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skfOrCompetitor: "",
      skfBearingDesignation : "",
      SKFManufacturer: "",
      manufacturer: "",
      competitorDesignationAndMarkings: " ",
      skfEquivalent: "",
    }
  }

  componentDidMount() {
    let values
    if ( this.props.parentSetValue !== undefined  && this.props.parentSetValue !== null) {
      values = this.props.parentSetValue
    } else {
      values = this.props.getValue(this.props.myName)
    }
    if ( values !== undefined ) {
      this.setState({
        skfOrCompetitor: values.skfOrCompetitor,
        skfBearingDesignation : values.skfBearingDesignation,
        SKFManufacturer: values.SKFManufacturer,
        manufacturer: values.manufacturer,
        competitorDesignationAndMarkings: values.competitorDesignationAndMarkings ? values.competitorDesignationAndMarkings : "",
        skfEquivalent: values.skfEquivalent ? values.skfEquivalent : "",
      })
    }
  }

  saveToStore(objectToSave, name) {
    if (this.props.parentHandleSave !== undefined ) {
      this.props.parentHandleSave(objectToSave, name)
    } else {
      this.props.setValue(objectToSave, name);
    }
  }

  handleChange = (value , name) => {

    var parts = name.split(".");
    var resultName = parts[parts.length - 1];
    let objectToSave = {
      skfOrCompetitor : this.state.skfOrCompetitor, 
      skfBearingDesignation: this.state.skfBearingDesignation,
      manufacturer: this.state.manufacturer,
      SKFManufacturer: this.state.SKFManufacturer,
      competitorDesignationAndMarkings: this.state.competitorDesignationAndMarkings,
      skfEquivalent: this.state.skfEquivalent,
    }

    if (resultName === "skfOrCompetitor") {
      this.setState({
        skfOrCompetitor : value
      });
      objectToSave.skfOrCompetitor = value
      if (value.value === "skf") {
        this.setState({
          competitorDesignationAndMarkings: "",
          manufacturer: "",
          skfEquivalent: "",
        })
        objectToSave.competitorDesignationAndMarkings = ""
        objectToSave.manufacturer = ""
        objectToSave.skfEquivalent = ""
      } else if (value.value === "competitor") {
        this.setState({
          skfBearingDesignation: "",
          SKFManufacturer: ""
        })
        objectToSave.skfBearingDesignation = ""
        objectToSave.SKFManufacturer = ""
      }

      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "skfBearingDesignation") {
      this.setState({
        skfBearingDesignation : value
      });
      objectToSave.skfBearingDesignation = value
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "manufacturer") {
      this.setState({
        manufacturer : value
      });
      objectToSave.manufacturer = value
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "competitorDesignationAndMarkings") {
      this.setState({
        competitorDesignationAndMarkings : value
      });
      objectToSave.competitorDesignationAndMarkings = value
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "skfEquivalent") {
      this.setState({ skfEquivalent : value });
      objectToSave.skfEquivalent = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "SKFManufacturer") {
      this.setState({
        SKFManufacturer : value
      });
      objectToSave.SKFManufacturer = value
      this.saveToStore(objectToSave, this.props.myName);
    }
  };


  renderCompetitor = () => {
    return (
      <React.Fragment key={this.state.skfOrCompetitor.label}>
        {this.props.connection ?
          getComponent(
            {
              "key": "listOfManufacturers",
              "type": "AutoCompleteCreatable",
              "description": "Competitor manufacturer",
              "args": [
                { "apiPath": "lists/bearingmanufacturers" },
                {
                  "valueLabelParams": {
                    "value": "manufacturer",
                    "label": "manufacturer"
                  }
                }
              ]
            },
            `${this.props.myName}.manufacturer`,
            this.handleChange,
            this.state.manufacturer
          ) : "List of manufacturers disabled"
        }
        {getComponent(
          {
            "key": "competitorDesignationAndMarkings",
            "type": "Text",
            "description": "Competitor designation and markings"
          },
          `${this.props.myName}.competitorDesignationAndMarkings`,
          this.handleChange,
          this.state.competitorDesignationAndMarkings
        )}
        {this.props.formtemplateId && getReportType(this.props.formtemplateId) === 'NAM' && (
          <>
            {getComponent({
              "key": "skfEquivalent",
              "type": "Text",
              "description": "SKF equivalent",
            },
              `${this.props.myName}.skfEquivalent`,
              this.handleChange,
              this.state.skfEquivalent
            )}
            <p>
              <a
                href="https://croesus.skf.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.intl.formatMessage({ id: 'getSkfEquivalent' })}
              </a>
            </p>
          </>
        )}
      </React.Fragment>
    )
  }
  renderSKF = () => {
    return (
      <React.Fragment key={this.state.skfOrCompetitor.label}>
      {getComponent(
        { 
        "key": "SKFManufacturer",
        "type": "AutoCompleteCreatable",
        "description": "SKF brand",
        "args": [
          { "options": [
              { "label": "gbcSkf", "value": "gbcSkf" },
              { "label": "kaydonSkf", "value": "kaydonSkf" },
              { "label": "mrcSkf", "value": "mrcSkf" },
              { "label": "peerSkf", "value": "peerSkf" },
              { "label": "skf", "value": "skf" },
              { "label": "skfCooperSkf", "value": "skfCooperSkf" }
            ] 
          },
        ]
      }, 
        `${this.props.myName}.SKFManufacturer`, 
        this.handleChange, 
        this.state.SKFManufacturer)
      }
      {getComponent(
      { 
        "key": "skfBearingDesignation",
        "type": "AutoCompleteCreatableBigList",
        "description": "SKF bearing designation",
        "args": [
          { "apiPath": "lists/json/designations" },
        ]
      },`${this.props.myName}.skfBearingDesignation`, this.handleChange, this.state.skfBearingDesignation)
      }
      </React.Fragment>
    )
  }

  renderSKFOrCompetitor = () => {

    if (this.state.skfOrCompetitor && this.state.skfOrCompetitor.value === 'skf') {
      return this.renderSKF()
    }  else if (this.state.skfOrCompetitor && this.state.skfOrCompetitor.value === 'competitor') {
      return this.renderCompetitor()
    } else {
      return null
    }
  }

  renderBearingDesignation = (formComponent, index) => {
    if ( formComponent !== undefined ) {
      return <React.Fragment key={index}>{getComponent(formComponent, `${this.props.myName}.${formComponent.key}`, this.handleChange, this.state.skfOrCompetitor)}</React.Fragment>
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.formComponentData.args.map( (formComponent, index) => {
          return this.renderBearingDesignation(formComponent, index)
        })}
        {this.renderSKFOrCompetitor()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    connection: state.connection,
    formtemplateId: state.currentCase.case.formtemplateId,
  };
}
export default injectIntl(connect(mapStateToProps)(BearingDesignationComponent))