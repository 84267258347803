import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import getComponent from "components/_Case/ComponentsList"
import _ from 'lodash'

export default function SystemNameComponent(props) {
  const [segmentType, setSegmentType] = useState('')
  const [systemNameValue, setSystemNameValue] = useState('')

  const savedSegment = useSelector(state => state.saveComponentValue.components.find(c =>
    c.name === 'assetDetails.assetTypeOrFunctionalAreaOrSystem.segment')
  )
  const segmentToSave = useSelector(state => state.saveComponentValue.componentsToSave.find(c =>
    c?.answer?.name === 'assetDetails.assetTypeOrFunctionalAreaOrSystem.segment')
  )

  useEffect(() => {
    setInitialSegmentValue(segmentToSave, savedSegment)
    setInitialSystemNameValue()
  }, [])

  useEffect(() => {
    segmentChangeHandler()
  }, [segmentToSave])

  const segmentChangeHandler = () => {
    if (!segmentToSave?.answer?.value) return

    const parsedValue = JSON.parse(segmentToSave.answer.value)
    const segment = getCurrentValue(parsedValue)

    if (_.isEqual(segment, segmentType)) {
      return
    }

    setSegmentType(segment)

    if (_.isEmpty(segment)) {
      setSystemNameValue('')
      saveValues('', props.myName)
      return
    }

    if (!isCustomSegmentValue(segmentTypes, segment)) {
      setSystemNameValue('')
      saveValues('', props.myName)
      return
    }

    const isCustomSystemName = isCustomSystemNameValue([...pulpPaperSystemNameOptions, ...metalsSystemNameOptions], systemNameValue)

    if (!isCustomSegmentValue(segmentTypes, segment) && isCustomSystemName) {
      setSystemNameValue('')
      saveValues('', props.myName)
      return
    }

    if (isCustomSegmentValue(segmentTypes, segment) && !isCustomSystemName) {
      setSystemNameValue('')
      saveValues('', props.myName)
      return
    }

    if (isCustomSegmentValue(segmentTypes, segment) && isCustomSystemName) {
      setSystemNameValue(systemNameValue)
      saveValues(systemNameValue, props.myName)
    }
  }

  const setInitialSystemNameValue = () => {
    let values
    if (props.parentSetValue) {
      values = props.parentSetValue(props.myName)
    } else {
      values = props.getValue(props.myName)
    }
    setSystemNameValue(values)
  }

  const setInitialSegmentValue = (newSegment, segmentFromState) => {
    if (newSegment) {
      if (newSegment?.answer.value) {
        const parsedValue = JSON.parse(newSegment.answer.value)
        if (parsedValue?.segment === segmentType) return
        setSegmentType(parsedValue?.segment)
        return
      }
    }
    if (segmentFromState) {
      if (segmentFromState?.value) {
        const parsedValue = JSON.parse(segmentFromState.value)
        const segmentValue = getCurrentValue(parsedValue)
        setSegmentType(segmentValue)
      }
    }
  }

  const changeHandler = (value, name) => {
    if (_.isEqual(value, systemNameValue)) return

    if (isCustomSystemNameValue([...pulpPaperSystemNameOptions, ...metalsSystemNameOptions], value)) {
      setSystemNameValue(value)
      saveValues(value, props.myName)
    } else {
      setSystemNameValue(value ? { ...value } : '')
      saveValues(value, props.myName)
    }
  }

  const saveValues = (value, name) => {
    if (props.parentHandleSave) {
      props.parentHandleSave(value, name)
    } else {
      props.setValue(value, name)
    }
  }

  const renderSystemNameOptions = () => {
    switch (segmentType) {
      case segmentTypes.PULP_PAPER: {
        return getComponent(
          {
            "key": "pulpPaperSystemName",
            "type": "AutoCompleteCreatable",
            "description": "Pulp & Paper",
            "required": true,
            "args": [{ "options": pulpPaperSystemNameOptions }],
          },
          props.myName,
          changeHandler,
          systemNameValue,
        )
      }
      case segmentTypes.METALS: {
        return getComponent(
          {
            "key": "metalsSystemName",
            "type": "AutoCompleteCreatable",
            "description": "Metals",
            "required": true,
            "args": [{ "options": metalsSystemNameOptions }],
          },
          props.myName,
          changeHandler,
          systemNameValue,
        )
      }
      default: {
        const currentValue = getCurrentValue(systemNameValue)
        return getComponent(
          {
            "key": "otherSystemName",
            "type": "Text",
            "description": "System name where asset is used",
            "required": true,
          },
          props.myName,
          changeHandler,
          currentValue,
        )
      }
    }
  }

  return <>{renderSystemNameOptions()}</>
}

const segmentTypes = {
  METALS: 'Metals',
  MINING: 'Mining',
  PULP_PAPER: 'Pulp & Paper',
}

const pulpPaperSystemNameOptions = [
  { "key": "paperMachine", "value": "Paper machine" },
  { "key": "tissueMachine", "value": "Tissue machine" },
  { "key": "pulpMachine", "value": "Pulp machine" },
  { "key": "boardMachine", "value": "Board machine" },
  { "key": "woodyard", "value": "Woodyard" },
]

const metalsSystemNameOptions = [
  { "key": "converter", "value": "Converter" },
  { "key": "continuousCaster", "value": "Continuous caster" },
  { "key": "rollingMill", "value": "Rolling mill" },
  { "key": "galvanizingMill", "value": "Galvanizing mill" },
]

const getCurrentValue = currentValue => currentValue && typeof currentValue === 'object' ? currentValue?.value : currentValue ? currentValue : ''
const isCustomSegmentValue = (data, value) => !_.some(data, type => type === value)
const isCustomSystemNameValue = (data, value) => !data.some(e => e?.value === value?.value || e?.value === value)