/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import httpService from "core/http-service";
import _ from "lodash";
import classnames from "classnames";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

const AutoCompleteComponent = props => {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.parentSetValue !== undefined && props.parentSetValue !== null) {
      setValue(props.parentSetValue);
    } else {
      setValue(props.getValue(props.myName));
    }
  }, [props, props.parentSetValue]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    loadSavedDataAndOptions();
  }, []);

  useEffect(() => {
    loadSavedDataAndOptions();
  }, [props.formComponentData.args])

  function loadSavedDataAndOptions() {
    let savedValue;
    let hasApi = false;
    let hasConfigOptions = false;
    let hasValueLabelParams = false;

    if (props.parentSetValue !== undefined && props.parentSetValue !== null) {
      savedValue = props.parentSetValue;
    } else {
      savedValue = props.getValue(props.myName);
    }

    if (savedValue !== undefined) {
      setValue(savedValue);
    }

    let apiPath = findValueInArray(props.formComponentData.args, "apiPath");
    let valueLabelParams = findValueInArray(
      props.formComponentData.args,
      "valueLabelParams"
    );

    let configOptions = findValueInArray(
      props.formComponentData.args,
      "options"
    );

    let translationNeeded = props.formComponentData.translate

    if (apiPath !== undefined) {
      apiPath = apiPath.apiPath;
      hasApi = true;
    } else {
      hasApi = false;
    }

    if (valueLabelParams !== undefined) {
      valueLabelParams = valueLabelParams.valueLabelParams;
      hasValueLabelParams = true;
    } else {
      hasValueLabelParams = false;
    }

    if (configOptions !== undefined) {
      configOptions = configOptions.options;
      hasConfigOptions = true;
    } else {
      hasConfigOptions = false;
    }

    if (hasApi) {
      setLoading(true);
      getOptionsFromAPI(apiPath, {"lang": props.language}).then(response => {
        if (response.data !== undefined) {
          let result = response.data.map(option => {
            if (hasValueLabelParams) {
              return {
                value: valueLabelParams.value ? option[valueLabelParams.value] : option,
                label: valueLabelParams.label.includes("+") ? valueLabelParams.label.split("+").map(lpart => option[lpart]).filter(part => part && part.length > 0).join(", ") : option[valueLabelParams.label]
              };
            } else {
              return { value: option, label: option };
            }
          });
          setLoading(false);
          setOptions(result);
        }
      });
    } else if (!hasApi) {
      if (hasConfigOptions) {
        let options = configOptions.map(option => {
          if (hasValueLabelParams) {
            return {
              value: option[valueLabelParams.value],
              label: props.intl.formatMessage({
                id: `${option[valueLabelParams.value]}`
              })
            };
          } else {
            if (translationNeeded === undefined) {
              return {
                value: option.value,
                label: props.intl.formatMessage({ id: `${option.value}` })
              };
            } else {
              return {
                value: option.value,
                label: option.label
              };
            }
          }
        });
        setOptions(options);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  function findValueInArray(array, value) {
    if (array !== undefined && value !== undefined) {
      return _.find(array, value);
    }
  }

  function getOptionsFromAPI(apiPath, params) {
    if (apiPath !== undefined) {
      return httpService
        .get(apiPath, params)
        .then(response => {
          return response;
        })
        .catch(error => {
          return error;
        });
    }
  }

  function onChangeSelectComponent(event) {
    if (event !== undefined && event !== null) {
      if (props.parentHandleSave !== undefined) {
        props.parentHandleSave(event, props.myName);
      } else {
        props.setValue(event, props.myName);
        setValue(event);
      }
    } else {
      // is cleared
      if (props.parentHandleSave !== undefined) {
        props.parentHandleSave("", props.myName);
      } else {
        props.setValue("", props.myName);
        setValue("");
      }
    }
  }

  return (
    <div className="input-shell">
      {props.formComponentData.description && (
        <label
          htmlFor={props.formComponentData.description}
          className={classnames(
            "input-shell__label",
            "input-shell__label--dark",
            { "input-shell__label--required": props.formComponentData.required }
          )}
        >
          {props.formComponentData.description}
        </label>
      )}

      <div
        className="input-shell__container"
        data-id={props.formComponentData.myName}
      >
        <Select
          onChange={onChangeSelectComponent}
          className="select__element"
          value={value}
          options={options}
          placeholder={props.formComponentData.placeholder ? props.formComponentData.placeholder : props.intl.formatMessage({ id: "customComponents.select" })}
          styles={ReactSelectStyles}
          isClearable={true}
          isLoading={ loading }
        />
      </div>
      {(props.formComponentData.required || props.formComponentData.showrequired) &&
        <div style={{ marginTop: '.5rem' }} className="input-shell__message-hint">
          <FormattedMessage id='formComponents.required' />
        </div>
      }
    </div>
  );
};

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default injectIntl(connect(mapStateToProps)(AutoCompleteComponent))
