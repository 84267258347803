import React, { useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const ImageItem = ({ imageUrl, imageNumber, imageCaption, intl, layout }) => {
  const connection = useSelector(state => state.connection, shallowEqual);
  const imageRef = useRef(imageUrl);
  
  useEffect(() => {
    imageRef.current.src = imageUrl;
  }, [connection]);

  const imageFigureAndNumber = `${intl.formatMessage({
    id: 'case.livePreview.image.figure'
  })} ${imageNumber}`;

  return (
    <div className={'live-preview-images-item ' + layout}>
      <div className="live-preview-images-item__media">
        <div className="live-preview-images-item__media-container">
          <img
            ref={imageRef}
            src={imageUrl}
            alt={
              imageFigureAndNumber !== ''
                ? imageFigureAndNumber
                : 'live preview image'
            }
            className="live-preview-image-item__media-image"
          />
        </div>
      </div>

      {(imageNumber !== '' || imageCaption !== '') && (
        <div className="live-preview-images-item__description">
          {imageNumber !== '' && (
            <span className="live-preview-images-item__media-figure">
              {imageFigureAndNumber}
            </span>
          )}{' '}
          {imageCaption !== '' && `${imageCaption}`}
        </div>
      )}
    </div>
  );
};

ImageItem.propTypes = {
  imageUrl: PropTypes.string,
  imageNumber: PropTypes.string,
  imageCaption: PropTypes.string
};



export default injectIntl(ImageItem);
