import _ from "lodash";

export function transformBearingInvestigationKeys(row) {
  const result = [];
  row.forEach((r) => {
    if (r.key.includes("operatingData.railwayWheelsetBearing")) {
      result.push(cloneRowAndAddToKey(r, ".axelLoad"));
      result.push(cloneRowAndAddToKey(r, ".trainSpeed"));
      result.push(cloneRowAndAddToKey(r, ".wheelDiameter"));
      result.push(cloneRowAndAddToKey(r, ".unsprungMass"));
    } else {
      result.push(r);
    }
  });
  return result;
}

function cloneRowAndAddToKey(row, keyAddition) {
  const newRow = {...row};
  newRow.key = newRow.key + keyAddition;
  return newRow;
}

export function handleDuplicateFields(preferedField, field, data) {
  if (data) {
    if (Object.keys(data).filter(k => k.includes(field)).length == 1) {
      return data;
    } else {
      return Object.keys(data).reduce((acc, key) => {
        if (key.includes(field) && key.includes(preferedField)) {
          acc[key] = data[key];
        } else if (!key.includes(field)) {
          acc[key] = data[key];  
        } 
        return acc;
      }, {});  
    }
    
  } else {
    return data;
  }
}

export function extractBearingInvestigationData(row, includeList, data) {
  let config = [];
  let answerObject = {};
  row.forEach((configObject) => {
    includeList.forEach((includeName) => {
      if (configObject.key.endsWith(includeName)) {
        config.push(configObject);
      }
    });
  });

  config.forEach((configElement) => {
    answerObject[configElement.key] = data[configElement.key]
  });

  return answerObject;
}


export function transformBearingInvestigationData(row, includeList, data) {
  let config = [];
  let answerObject = {};
  row.forEach((configObject) => {
    includeList.forEach((includeName) => {
      if (configObject.key.includes(includeName)) {
        config.push(configObject);
      }
    });
  });

  config.forEach((configElement) => {
    let answer = data[configElement.key];
    if (answer !== undefined && answer !== null) {
      if (typeof answer === "object") {
        if (answer.hasOwnProperty("unitSelected")) {
          if (
            answer.value !== undefined &&
            answer.unitSelected.label !== undefined &&
            answer.value
          ) {
            answerObject[
              configElement.key
              ] = `${answer.value} ${answer.unitSelected.label}`;
          }
        } else if (answer.hasOwnProperty("continuousOrIntermittent")) {
          answerObject[configElement.key] =
            answer.continuousOrIntermittent.label;
          if (
            answer.numberOfHours !== null &&
            answer.numberOfHours !== undefined
          ) {
            if (
              answer.numberOfHours.value !== undefined &&
              answer.numberOfHours.unitSelected.label !== undefined
            ) {
              answerObject[
                `${configElement.key}.operationNumber`
                ] = `${answer.numberOfHours.value} ${answer.numberOfHours.unitSelected.label}`;
            }
          }
        } else if (answer.hasOwnProperty("date")) {
          answerObject[configElement.key] = answer.date;
        } else if (answer.hasOwnProperty("label")) {
          answerObject[configElement.key] = answer.label;
        } else if (answer.hasOwnProperty("greaseOrOil")) {
          answerObject[configElement.key] = answer.greaseOrOil.label;
          if (answer.greaseType !== null && answer.greaseType !== undefined) {
            answerObject[`${configElement.key}.greaseType`] =
              answer.greaseType.label;
          }
          if (answer.oilType !== null && answer.oilType !== undefined) {
            answerObject[`${configElement.key}.oilType`] = answer.oilType.label;
          }
          if (
            answer.filterRating !== null &&
            answer.filterRating !== undefined
          ) {
            answerObject[`${configElement.key}.filterRating`] =
              answer.filterRating;
          }
          if (
            answer.greaseTypeGrams !== null &&
            answer.greaseTypeGrams !== undefined
          ) {
            if (
              answer.greaseTypeGrams.value &&
              answer.greaseTypeGrams.unitSelected
            ) {
              answerObject[
                `${configElement.key}.greaseTypeGrams`
                ] = `${answer.greaseTypeGrams.value} ${answer.greaseTypeGrams.unitSelected.label}`;
            }
          }
          if (
            answer.oilBathBelowShaft !== null &&
            answer.oilBathBelowShaft !== undefined
          ) {
            if (
              answer.oilBathBelowShaft.value !== undefined &&
              answer.oilBathBelowShaft.unitSelected !== undefined
            ) {
              answerObject[
                `${configElement.key}.oilBathBelowShaft`
                ] = `${answer.oilBathBelowShaft.value} ${answer.oilBathBelowShaft.unitSelected.label}`;
            }
          }
          if (
            answer.circulatingOil !== null &&
            answer.circulatingOil !== undefined
          ) {
            if (
              answer.circulatingOil.value &&
              answer.circulatingOil.unitSelected
            ) {
              answerObject[
                `${configElement.key}.circulatingOilFlowRate`
                ] = `${answer.circulatingOil.value} ${answer.circulatingOil.unitSelected.label}`;
            }
          }
        } else if (answer.hasOwnProperty("skfOrCompetitor")) {
          if (
            answer.skfBearingDesignation !== null &&
            answer.skfBearingDesignation !== undefined
          ) {
            answerObject[`${configElement.key}.skfBearingDesignation`] =
              answer.skfBearingDesignation.value;
          }
          if (
            answer.manufacturer !== null &&
            answer.manufacturer !== undefined
          ) {
            answerObject[`${configElement.key}.manufacturer`] =
              answer.manufacturer.label;
          }
          if (
            answer.competitorDesignationAndMarkings !== null &&
            answer.competitorDesignationAndMarkings !== undefined
          ) {
            answerObject[
              `${configElement.key}.competitorDesignationAndMarkings`
              ] = answer.competitorDesignationAndMarkings;
          }
          if (
            answer.SKFManufacturer !== null &&
            answer.SKFManufacturer !== undefined
          ) {
            answerObject[`${configElement.key}.SKFManufacturer`] =
              answer.SKFManufacturer.label;
          }
        } else if (answer.hasOwnProperty("country")) {
          answerObject[configElement.key] = answer.country.label;
          if (answer.company !== null && answer.company !== undefined) {
            answerObject[`${configElement.key}.company`] = answer.company.label;
          }
          if (answer.location !== null && answer.location !== undefined) {
            answerObject[`${configElement.key}.location`] =
              answer.location.label;
          }
        } else if (answer.hasOwnProperty("shaftMaterialType")) {
          answerObject[configElement.key] = answer.shaftMaterialType.label;
          if (
            answer.stainlessSteelType !== null &&
            answer.stainlessSteelType !== undefined
          ) {
            answerObject[`${configElement.key}.stainlessSteelType`] =
              answer.stainlessSteelType;
          }
        } else if (answer.hasOwnProperty("imageLayout")) {
          answerObject[configElement.key] = answer;
        } else if (answer.hasOwnProperty("disposition")) {
          if (answer.disposition !== null && answer.disposition !== undefined) {
            answerObject[configElement.key] = answer.disposition.label;
          }
          if (answer.scrapDate !== null && answer.scrapDate !== undefined) {
            answerObject[`${configElement.key}.scrapDate`] =
              answer.scrapDate.date;
          }
        } else if (answer.hasOwnProperty("functionalLocation")) {
          answerObject[configElement.key] = answer.functionalLocation.label;
          if (answer.hasOwnProperty("assetType")) {
            answerObject[`${configElement.key}.assetType`] =
              answer.assetType.label;
          }
          if (answer.hasOwnProperty("positionInAsset")) {
            answerObject[`${configElement.key}.positionInAsset`] =
              answer.positionInAsset.label;
          }
        } else if (answer.hasOwnProperty("isRailwayWheelsetBearing")) {
          if (answer.isRailwayWheelsetBearing === true) {
            if (isValuePresent(answer.axelLoad) && isValuePresent(answer.axelLoad.unitSelected)) {
              answerObject[`${configElement.key}.axelLoad`] = `${answer.axelLoad.value} ${answer.axelLoad.unitSelected.label}`;
            }
            if (isValuePresent(answer.trainSpeed) && isValuePresent(answer.trainSpeed.unitSelected)) {
              answerObject[`${configElement.key}.trainSpeed`] = `${answer.trainSpeed.value} ${answer.trainSpeed.unitSelected.label}`;
            }
            if (isValuePresent(answer.wheelDiameter) && isValuePresent(answer.wheelDiameter.unitSelected)) {
              answerObject[`${configElement.key}.wheelDiameter`] = `${answer.wheelDiameter.value} ${answer.wheelDiameter.unitSelected.label}`;
            }
            if (isValuePresent(answer.unsprungMass) && isValuePresent(answer.unsprungMass.unitSelected)) {
              answerObject[`${configElement.key}.unsprungMass`] = `${answer.unsprungMass.value} ${answer.unsprungMass.unitSelected.label}`;
            }
          }
        } else {
          // is allPartsAvailable
          if (configElement.key.includes("allPartsAvailable")) {
            answerObject[configElement.key] = answer
              .map((object) => object.label)
              .join(", ");
          }
        }
        // else if some other signifying property
      } else {
        answerObject[configElement.key] = data[configElement.key];
      }
    }
  });
  return answerObject;
}

export function extractConfigFor(value, index, config) {
  return config[index].filter((item) => {
    return item.key.includes(value);
  });
}

export function getConfigFor(value, index, config) {
  return config[index].filter((item) => {
    return item.key.endsWith(value);
  });
}

export function imageSectionNames(bearingIndex, config) {
  let imageSectionConfig = extractConfigFor(
    "componentPartsInvestigation",
    bearingIndex,
    config
  );
  return imageSectionConfig;
}

export function imageAnalysisNames(bearingIndex, config, isRailway) {
  const primary = isRailway ? "primaryFailureAndCauseIso" : "primaryFailureAndCause";
  const additional = isRailway ? "additionalFailureAndCauseIso" : "additionalFailureAndCause";
  let configPrim = getConfigFor(
    primary,
    bearingIndex,
    config
  );
  let configAdd = getConfigFor(
    additional,
    bearingIndex,
    config
  );
  return configPrim.concat(configAdd);
}

export function getImageObjects(name, data) {
  let answer = data[name];
  let imageObjects = [];
  if (answer !== undefined && answer !== null) {
    if (answer.imageUrls) {
      answer.imageUrls.forEach((imgObj) => {
        imageObjects.push(imgObj);
      });
    }
  }
  return imageObjects;
}

export function isImageEmpty(value) {
  if (value !== undefined) {
    if (
      value.observations === "" &&
      Array.isArray(value.imageUrls) &&
      value.imageUrls.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export function getImageLayoutObject(value) {
  if (value !== undefined && value.imageLayout !== undefined && value.imageLayout !== "" && value.imageLayout.label) {
    return value.imageLayout;
  } else {
    return {
      value: "live-preview-images__container--3",
      label: "Three figures",
    };
  }
}

export function isNotNull(value) {
  return value !== null && value !== undefined;
}

export function isValuePresent(value) {
  return isNotNull(value) && value !== '';
}

export function getImageLayout(value) {
  if (value !== undefined && value.imageLayout !== undefined) {
    return value.imageLayout.value
      ? value.imageLayout.value
      : "live-preview-images__container--3";
  } else {
    return "live-preview-images__container--3";
  }
}

export function fixBearingConfig(config, bearingsCount) {
  const newData = config.reduce((acc, curr) => {
    for (let i = 0; i < bearingsCount; i++) {
      const key = curr.key.split(".");
      key.splice(1, 0, `bearingInvestigation[${i}]`);
      acc.push({
        key: key.join("."),
        isRequired: curr.isRequired,
        sortOrder: curr.sortOrder,
        imageIsNext: curr.imageIsNext ? curr.imageIsNext : false,
        isFullWidth: curr.isFullWidth ? curr.isFullWidth : false,
        isRichText: curr.isRichText,
        isContainer: curr.isContainer,
        cellType: curr.cellType,
        index: i
      });
    }
    return acc;
  }, []);

  let arrays = _.chunk(_.orderBy(newData, ["index"], ["asc"]), config.length);
  let newArrays = [];
  arrays.forEach((arr) => {
    newArrays = [...newArrays, ..._.orderBy(arr, ["sortOrder"], ["asc"])];
  });
  return _.chunk(newArrays, config.length);
}
