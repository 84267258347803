import React, { useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import SvgButtonBarDuplicate from "./../../svg/ButtonBarDuplicate"
import duplicateCase from "./../ButtonActions/duplicateCase";


const ButtonDuplicate = ({ buttonSize }) => { 

    const connection = useSelector(state => state.connection, shallowEqual);
    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const currentCaseAccess = useSelector(state => state.currentCaseAccess, shallowEqual);

    const dispatch = useDispatch();

    const intl = useIntl();
    const [isDuplicating, setIsDuplicating] = useState(false);
    
    const onDuplicate = () => {
        setIsDuplicating(true);
        duplicateCase(currentCaseId, currentCaseStatus, currentCaseAccess.edit, dispatch, intl).then(() => { 
            setIsDuplicating(false);
        })
    }

    const isVisible = () => true;
    const isLoading = () => isDuplicating;
    const isDisabled = () => !connection;

    return isVisible() && <button
        id="buttonDuplicate"
        type="button"
        onClick={onDuplicate}
        className={classnames("button", "button--icon-only", { "button--icon-saving": isLoading() }, { "button--icon-disabled": isDisabled() })}
        disabled={isDisabled()}>
        <SvgButtonBarDuplicate size={buttonSize} title={intl.formatMessage({ id: "formRenderer.duplicate" })} />
    </button>;
}

export default ButtonDuplicate;